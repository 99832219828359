import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Div100vh from "react-div-100vh"

const CollaboratorsStyled = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 7rem 9rem;
  flex-wrap: wrap;

  a {
    img {
      opacity: 0.5;
    }
    &:hover {
      img {
        opacity: 1;
        filter: grayscale(0);
      }
    }
  }

  ${breakpoint("mobile")`
  padding: 4rem 3rem;

  `}
  ${breakpoint("tablet")`
  padding: 5rem 6rem;

  `}
  ${breakpoint("desktop")`
  padding: 7rem 7rem;

  `}
`

const Collaborator = styled.div`
  width: 25%;
  border-radius: 100%;
  display: block;
  ${breakpoint("mobile")`
  width: 50%;

  `}
  ${breakpoint("tablet")`
  width: 33.333333%;

  `}
  ${breakpoint("desktop")`
  width: 25%;

  `}
`

const CollaboratorStyled = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
  font-family: warnock-pro-display, serif;
  color: black;
  /* text-transform: uppercase;
  letter-spacing: 0.1rem; */
`

const CollaboratorTitle = styled.div`
  text-align: center;
  position: relative;
  z-index: 10;
  font-size: 2rem;
  line-height: 1;
  ${breakpoint("mobile")`
  font-size: 1.5rem;

  `}
  ${breakpoint("tablet")`
  font-size: 2rem;

  `}
`

const CollaboratorName = styled.div`
  text-align: center;
  position: relative;
  z-index: 10;
  font-style: italic;
  ${breakpoint("mobile")`
  font-size: 1rem;

  `}
  ${breakpoint("tablet")`
  font-size: 1rem;

  `}
`

const CollaboratorImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(80%);
  }
`

const Collaborators = () => {
  const [colabHover, setColabHover] = useState(null)

  const data = useStaticQuery(graphql`
    query ColabQuery {
      colabs: allWordpressWpCollaborator(sort: {fields: [menu_order], order: ASC}) {
        edges {
          node {
            content
            title
            slug
            path
            acf {
              image {
                localFile {
                  publicURL
                }
              }
              title
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <CollaboratorsStyled>
        {data.colabs.edges.map((e, i) => {
          return (
            <Collaborator
              onMouseEnter={() => {
                setColabHover({ title: e.node.acf.title, name: e.node.title })
              }}
              onMouseLeave={() => {
                setColabHover(null)
              }}
            >
              <Link to={e.node.path} title={e.node.title}>
                <div
                  style={{
                    width: "100%",
                    height: "0",
                    paddingBottom: "100%",
                    borderRadius: "100%",
                    position: "relative",
                  }}
                >
                  <CollaboratorStyled>
                    {colabHover && (
                      <>
                        {colabHover.name !== e.node.title && (
                          <>
                            <CollaboratorTitle>
                              {colabHover.title}
                            </CollaboratorTitle>
                            <CollaboratorName>
                              {colabHover.name}
                            </CollaboratorName>
                          </>
                        )}

                        {colabHover.name === e.node.title && (
                          <CollaboratorImage>
                            <img
                              src={e.node.acf.image.localFile.publicURL}
                              alt={e.node.title}
                            />
                          </CollaboratorImage>
                        )}
                      </>
                    )}
                    {!colabHover && (
                      <>
                        <CollaboratorTitle>
                          {e.node.acf.title}
                        </CollaboratorTitle>
                        <CollaboratorName>{e.node.title}</CollaboratorName>
                        <CollaboratorImage>
                          <img
                            src={e.node.acf.image.localFile.publicURL}
                            alt={e.node.title}
                          />
                        </CollaboratorImage>
                      </>
                    )}
                  </CollaboratorStyled>
                </div>
              </Link>
            </Collaborator>
          )
        })}
      </CollaboratorsStyled>
    </>
  )
}

export default Collaborators
