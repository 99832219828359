import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Collaborators from "../components/Collaborators"

const IndexPage = () => (
  <Layout page="collaborators">
    <SEO title="Collaborators" />
    <Collaborators></Collaborators>
  </Layout>
)

export default IndexPage
